<template>
  <div class="bottom-charts">
    <!-- <div class="bcci-header">人员日操作数</div>
    <dv-conical-column-chart
      :config="config"
      style="width:95%;"
    />-->
    <!-- <dv-charts
      style="width:100%;height:200px;"
      :option="option"
    />-->
    <!-- <div id="container" style="width:100%;height:200px;"></div> -->
    <ve-histogram
      ref="barChart"
      width="100%"
      height="100%"
      :data="barChartData"
      :settings="barChartSetting"
      :colors="chartColors"
      :grid="barGrid"
      :extend="barChartExtend"
    ></ve-histogram>
  </div>
</template>

<script>
import LabelTag from "./LabelTag";
// import screen from "@/api/screen.js";
import Charts from "@jiaminghi/charts";
import api from "../../../util/extra-api";
export default {
  name: "BottomCharts",
  components: {
    //LabelTag
  },
  data() {
    return {
      barChartData: {
            columns: ["submitName", "count"],
            rows: []
          },
      chartColors: ["#37a2da"],
      barChartSetting: {
        labelMap: {
          submitName: "人员",
          count: "次数"
        }
      },
      barChartExtend: {
        title: {
          text: "人员总质控数",
          textStyle: {
            color: "#fff"
          }
        },
        series: {
          type: "bar",
          barMaxWidth: 30
        },
        legend: {
          top: "2%",
          textStyle: {
            color: "#fff"
          }
        },
        xAxis: {
          axisLabel: {
            show: true,
            textStyle: {
              color: "#fff"
            }
          }
        },
        yAxis: {
          minInterval: 1,
          axisLabel: {
            show: true,
            textStyle: {
              color: "#fff"
            }
          }
        }
      },
      barGrid: {
        bottom: "2%"
      }
    };
  },
  watch: {
    "barChartData.rows"(v) {
      this.$nextTick(_ => {
        this.$refs[`barChart`].echarts.resize();
      });
    }
  },
  mounted() {
    this.getData();
  },
    methods: {
      getData() {
        api.get("/v1/pc/plantingScreen/oneChart4",{companyId:localStorage.getItem("company_id")}).then(response => {
          const data = response.data;
          this.barChartData = {
            columns: ["submitName", "count"],
            rows: data
          };
        });
      }
    }
};
</script>

<style lang="scss">
.bottom-charts {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;

  .bc-chart-item {
    width: 25%;
    height: 100%;
    padding-top: 20px;
    box-sizing: border-box;
  }

  .bcci-header {
    height: 50px;
    text-align: center;
    line-height: 50px;
    font-size: 20px;
  }

  .dv-active-ring-chart {
    height: 100%;
  }

  .label-tag {
    height: 30px;
  }

  .active-ring-name {
    font-size: 18px !important;
  }

  .decoration-1,
  .decoration-2,
  .decoration-3 {
    display: absolute;
    left: 0%;
  }
}
</style>
