<template>
  <div class="left-chart-1">
    <div class="lc1-header">各基地农事农活质控次数占比</div>
    <div class="lc1-header" @click="changeData" style="cursor:pointer">[{{baseName}}]</div>
    <div style="width:100%;height:60vh">
      <transition name="el-zoom-in-center">
        <dv-scroll-ranking-board
          v-if="chartFlag"
          :config="config"
          style="width:100%;height:60vh"
          :rowNum="rowNum"
        />
      </transition>
    </div>
    <dv-decoration-2 style="height:10px;" />
  </div>
</template>

<script>
// import screen from "@/api/screen.js";
import api from "../../../util/extra-api";
export default {
  name: "LeftChart1",
  data() {
    return {
      rowNum: 6,
      list: [],
      config: {
        data: [],
        colors: ["#00baff", "#3de7c9", "#fff", "#ffc530", "#469f4b","#00baff", "#3de7c9", "#fff", "#ffc530", "#469f4b"]
      },
      timer: "",
      timer1: "",
      value: 0,
      chartFlag: true,
      baseName: "基地名称"
    };
  },
  mounted() {
    this.getData();
    const _this = this;
    this.timer = setInterval(function() {
      if (_this.list.length > 1) {
        _this.changeData();
      }
    }, 10000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
    clearTimeout(this.timer1);
  },
  methods: {
    getData() {
      api.get("/v1/pc/plantingScreen/oneChart3",{companyId:localStorage.getItem("company_id")}).then(response => {
        this.list = response.data;
        const { config } = this;
        this.config = {
          ...config,
          data: this.list[this.value].farmingAccountedForList.map(m =>{
            return {
              name: m.farmName,
              value: m.count
            }
          })
        };
        this.baseName = this.list[this.value].name;
        this.chartFlag = true;
        this.value++;
      });
    },
    changeData() {
      this.chartFlag = false;
      const { config } = this;
      const data = this.list[this.value].farmingAccountedForList.map(m =>{
        return {
          name: m.farmName,
          value: m.count
        }
      })
      this.config = {
        ...config,
        data
      };
      // this.config.data = this.list[this.value].data;
      this.baseName = this.list[this.value].name;
      this.value++;
      if (this.value == this.list.length) {
        this.value = 0;
      }
      this.timer1 = setTimeout(_ => {
        this.chartFlag = true;
      }, 500);
    }
  }
};
</script>

<style lang="scss">
.left-chart-1 {
  width: 100%;
  display: flex;
  flex-grow: 0;
  flex-direction: column;

  .lc1-header {
    text-align: center;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    margin-bottom: 20px;
  }

  .lc1-details {
    height: 50px;
    font-size: 16px;
    display: flex;
    align-items: center;
    text-indent: 20px;

    span {
      color: #096dd9;
      font-weight: bold;
      font-size: 35px;
      margin-left: 20px;
    }
  }

  .lc1-chart {
    flex: 1;
  }
}
</style>
