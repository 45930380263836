<template>
  <div class="right-chart-1">
    <div v-if="company_id == '2581597'" class="rc1-header" style="margin-bottom:10px;">园区基地分布</div>
    <div v-else class="rc1-header" style="margin-bottom:10px;">全国基地分布</div>

    <div class="rc1-chart-container">
      <div style="height: 80%">
       <ve-bmap ref="mapRef" class="right" :settings="chartSettings" :series="chartSeries" height="100%" :tooltip="chartTooltip"></ve-bmap>
      </div>
      <!-- <dv-capsule-chart
        class="right"
        :config="config"
      />-->
      <div style="height: 20%;font-size: 16px">
       <dv-scroll-board :config="config"/>
      </div>
    </div>
  </div>
</template>

<script>
// import screen from "@/api/screen.js";
import { mapConfig } from "./custom_map_config.js";
import api from "../../../util/extra-api";
import { local} from '@/util/util'
export default {
  name: "RightChart1",
  data() {
    this.chartTooltip = {
      show: true,
      formatter: function (params, ticket, callback) {
        return `${params.marker}${params.value[2]}<br>地址:${params.value[3]}`;
      }
    };
    this.chartSettings = {
      key: "Ns87bUghsWzPirriDs7uPGPYQOlGUKYQ",
      bmap: {
        center: [120, 30],
        zoom: 5,
        roam: true,
        mapStyle: { styleJson: mapConfig },
      }
    };
    return {
      chartSeries: null,
      config: {
      },
      company_id: null
    };
  },
  mounted() {
    this.company_id = local.get("company_id");
    this.getData();
  },
  methods: {
    getData() {
      api.get("/v1/pc/plantingScreen/oneChart1",{companyId:localStorage.getItem("company_id")}).then(response => {
        const data = response.data;

        let baseData = this.convertData(data);
        let arr = [];
        let pointArr = [];
        let echarts = this.$refs.mapRef.echarts;
        echarts.clear();
        baseData.forEach((item, index) => {
          arr.push({
            type: "effectScatter",
            coordinateSystem: "bmap",
            zlevel: 2,
            rippleEffect: {
              //涟漪特效
              period: 4, //动画时间，值越小速度越快
              brushType: "stroke", //波纹绘制方式 stroke, fill
              scale: 4 //波纹圆环最大限制，值越大波纹越大
            },
            symbol: "circle",
            itemStyle: {
              normal: {
                color: "#19d4ae"
              }
            },
            data: item
          });
        });
        let t = setTimeout(() =>{

          data.forEach(item => {
            pointArr.push(new window.BMap.Point(item.longitude, item.latitude));
          });

        this.chartSeries = arr;
        echarts.setOption({
          bmap: this.chartSettings.bmap,
          series: this.chartSeries
        });
        let map = echarts
          .getModel()
          .getComponent("bmap")
          .getBMap();
          let view = map.getViewport(eval(pointArr));
          let mapZoom = view.zoom;
          let centerPoint = view.center;
          map.centerAndZoom(centerPoint,5);

        },500)
        // let point = new window.BMap.Point(data[0].longitude, data[0].latitude);
        // map.centerAndZoom(point, 4);
        const configData = data.map(m =>{
          return [
            m.baseName,
            m.addressName
          ]
        })
        this.config = {
          header: ['基地名称', '基地地址'],
          data: configData,
          columnWidth: [180],
          align: ['center'],
          headerBGC: '#2570a1',
          rowNum: 3
        }
      });
    },
    convertData(data) {
      let result = [];
      for (let i = 0; i < data.length; i++) {
        let formattedData = [
          [
            data[i].longitude,
            data[i].latitude,
            data[i].baseName,
            data[i].addressName
          ]
        ];
        result.push(formattedData);
      }
      return result;
    }
  }
};
</script>

<style lang="scss">
.right-chart-1 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .rc1-header {
    font-size: 24px;
    font-weight: bold;
    height: 24px;
    line-height: 30px;
  }

  .rc1-chart-container {
    /*flex: 1;*/
    /*display: flex;*/
    height: 100%;
  }

  .left {
    width: 30%;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .number {
      font-size: 34px;
      color: #096dd9;
      font-weight: bold;
      margin-bottom: 30px;
    }
  }

  .right {
    flex: 1;
    padding-bottom: 20px;
    padding-right: 20px;
    box-sizing: border-box;
  }
}
</style>
