<template>
  <div id="data-view">
    <div class="bg-container" style="width: 100%; height: 100%; transform: scale(1);">
      <div class="t-head jac">
        <div style="position:absolute;margin-top: -10px;left: 20px">
          <span @click="play" v-if="showPlay" style="font-size: 40px;text-align: left;padding: 0 20px;cursor: pointer"><i class="el-icon-video-play"></i></span>
          <span @click="pause" v-else style="font-size: 40px;text-align: left;padding: 0 20px;cursor: pointer"><i class="el-icon-video-pause"></i></span>
        </div>
        <h1 v-if="company_id == '2581597'">园区质控追溯总览大屏</h1>
        <h1 v-else>企业质控追溯总览大屏</h1>
        <full-screen style="position: absolute;right: 20px"></full-screen>
      </div>

      <dv-border-box-1 class="main-chart-container">
        <dv-border-box-3 class="left-chart-container">
          <Left-Chart-1 />
<!--          <Left-Chart-3 />-->
        </dv-border-box-3>
        <div class="right-main-container">
          <div class="rmc-top-container">
            <dv-border-box-8 class="rmctc-left-container">
              <Center-Cmp />
            </dv-border-box-8>

            <div class="rmctc-right-container">
              <dv-border-box-5 class="rmctc-chart-1">
                <Right-Chart-1 />
              </dv-border-box-5>
            </div>
          </div>
          <div class="rmc-bottom-container">
            <dv-border-box-4 class="rmctc-chart-1">
              <Bottom-Charts />
            </dv-border-box-4>
          </div>
        </div>
      </dv-border-box-1>
    </div>
  </div>
</template>

<script>
import LeftChart1 from "./LeftChart1";
import bus from '@/util/bus'
import CenterCmp from "./CenterCmp";
import RightChart1 from "./RightChart1";
import BottomCharts from "./BottomCharts";
import { local} from '@/util/util'
export default {
  name: "DataView",
  components: {
    LeftChart1,
    //LeftChart2,
    //LeftChart3,
    CenterCmp,
    RightChart1,
    //RightChart2,
    BottomCharts
  },
  data() {
    return {
      dateDay: null,
      dateYear: null,
      showPlay: true,
      company_id: null

    };
  },
  mounted() {
    this.company_id = local.get("company_id");
    if(this.$route.query.fullScreen === 1){
      this.showPlay = false
    }

  },
  methods:{

    play(){
      this.showPlay = false
      bus.$emit('fullScreen',{fullScreen: true})
      bus.$emit('refreshChart',{show: true})
      let el = document.documentElement;
      ( el.requestFullscreen || el.webkitRequestFullScreen || el.mozRequestFullScreen || el.msRequestFullscreen).call(el);

    },
    pause(){
      this.showPlay = true
      clearInterval(local.get('t'))
      local.del('t')
      let full = this.$route.query.fullScreen ? this.$route.query.fullScreen : null
      //console.log(full)
      this.$router.push('controlScreen'); //再跳转路由路径，query参数没带过去，所以被清除了
      if(!full){
        location.reload()
      }
      bus.$off('fullScreen')
      const isFullScreen = document.fullscreenElement
      if(isFullScreen){
        if (document.exitFullscreen) {
          document.exitFullscreen()
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen()
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen()
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen()
        }
      }
    }
  }
};
</script>


<style lang="scss">
#data-view {
  width: 100%;
  height: 100%;
  background-color: #030409;
  color: #fff;

  .bg-container {
    background-image: url("./img/bg.png");
    background-size: 100% 100%;
    box-shadow: 0 0 3px blue;
    display: flex;
    flex-direction: column;
  }

  .main-header {
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .mh-left {
      font-size: 20px;
      color: rgb(1, 134, 187);

      a:visited {
        color: rgb(1, 134, 187);
      }
    }

    .mh-middle {
      font-size: 30px;
    }

    .mh-left,
    .mh-right {
      width: 15%;
    }
  }

  .main-chart-container {
    padding: 5px;
    height: 100%;
    .border-box-content {
      padding: 20px;
      box-sizing: border-box;
      display: flex;
    }
  }

  .left-chart-container {
    width: 22%;
    height: 100%;
    padding: 10px;
    box-sizing: border-box;

    .border-box-content {
      flex-direction: column;
    }
  }

  .right-main-container {
    width: 78%;
    padding-left: 5px;
    box-sizing: border-box;
  }

  .rmc-top-container {
    height: 70%;
    display: flex;
  }

  .rmctc-left-container {
    width: 65%;
  }

  .rmctc-right-container {
    width: 35%;
  }

  .rmc-bottom-container {
    height: 30%;
  }

  .rmctc-chart-1,
  .rmctc-chart-2 {
    height: 100%;
  }
}
body::-webkit-scrollbar {
  display: none;
}
body {
  -ms-overflow-style: none;
}
</style>
