<template>
  <div class="center-cmp">
    <div class="cc-header">
      <dv-decoration-1 style="width:180px;height:50px;" />
      <div>近三十日质控总数</div>
      <dv-decoration-1 style="width:180px;height:50px;" />
    </div>

    <div class="cc-details">
      <div class="card">{{total.first || 0}}</div>
      <div class="card">{{total.second || 0}}</div>
      <div class="card">{{total.third || 0}}</div>
      <div class="card">{{total.forth || 0}}</div>
    </div>

    <div v-if="company_id == '2581597'" class="cc-main-container">
        <Echart
                v-if="showChart"
                :options="option"
                id="bottomLeftChart"
                height="100%"
                width="100%"
        ></Echart>
      </div>
    <div v-else class="cc-main-container">
      <div class="ccmc-left">
        <div
          class="station-info"
          v-if="list.farmingAccountedFors&&list.farmingAccountedFors[0]"
        >
          {{list.farmingAccountedFors[0].farmName}}<span>{{list.farmingAccountedFors[0].count}}</span>
        </div>
        <div
          class="station-info"
          v-if="list.farmingAccountedFors&&list.farmingAccountedFors[1]"
        >
          {{list.farmingAccountedFors[1].farmName}}<span>{{list.farmingAccountedFors[1].count}}</span>
        </div>
      </div>

      <dv-active-ring-chart
        class="ccmc-middle"
        :config="config"
      />
      <div class="ccmc-right">
        <div
          class="station-info"
          v-if="list.farmingAccountedFors&&list.farmingAccountedFors[2]"
        >
          <span>{{list.farmingAccountedFors[2].count}}</span>{{list.farmingAccountedFors[2].farmName}}
        </div>
        <div
          class="station-info"
          v-if="list.farmingAccountedFors&&list.farmingAccountedFors[3]"
        >
          <span>{{list.farmingAccountedFors[3].count}}</span>{{list.farmingAccountedFors[3].farmName}}
        </div>
      </div>

      <LabelTag :config="labelConfig" />
    </div>
  </div>
</template>

<script>
import LabelTag from "./LabelTag";
// import screen from "@/api/screen.js";
import api from "../../../util/extra-api";
import { local} from '@/util/util'
import bus from '@/util/bus'
import Echart from '@/common/echart'
export default {
  name: "CenterCmp",
  components: {
    LabelTag,
    Echart
  },
  data() {
    return {
      list: {},
      chartFlag: false,
      config: {
        data: [],
        color: ["#00baff", "#3de7c9", "#fff", "#ffc530", "#469f4b"],
        lineWidth: 30,
        radius: "55%",
        activeRadius: "60%"
      },
      total: {
        first: "",
        second: "",
        third: "",
        forth: ""
      },
      labelConfig: {
        data: []
      },
      showChart: true,
      company_id: null,
      option: {},
      cdata: {
        count1:[],
        count2:[],
        name: [],
        end: 100
      }
    }
  },
  watch: {
    cdata: {
      handler (newData) {
        this.option = {
          // backgroundColor:'#031d33',
          dataZoom: [
            {
              type: "inside",
              show: true,
              start: 0,
              end: newData.end,
              xAxisIndex: [0],
            }
          ],
          legend: {
            top: "20",
            x: "center",
            textStyle: {
              fontSize: 16,
              color: "rgba(101, 213, 255, 1)"
            },
            icon:
                    "path://M512 881.777778 512 881.777778C716.222629 881.777778 881.777778 716.222629 881.777778 512 881.777778 307.777371 716.222629 142.222222 512 142.222222 307.777373 142.222222 142.222222 307.777371 142.222222 512 142.222222 716.222629 307.777373 881.777778 512 881.777778L512 881.777778ZM512 1024 512 1024C229.230208 1024 0 794.769789 0 512 0 229.230211 229.230208 0 512 0 794.769789 0 1024 229.230211 1024 512 1024 794.769789 794.769789 1024 512 1024L512 1024Z",
            itemWidth:8, // 设置宽度
            itemHeight:8, // 设置高度、
            itemGap: 12 // 设置间距
          },
          tooltip: {
            show: true,
            trigger: "axis", //axis , item
            backgroundColor: "RGBA(0, 49, 85, 1)",
            borderColor: "rgba(0, 151, 251, 1)",
            borderWidth: 1,
            borderRadius: 0,
            textStyle: {
              color: "#BCE9FC",
              fontSize: 16,
              align: "left"
            },
            formatter(params) {
              for (let x in params) {
                return (
                        params[x].name +
                        " : " +
                        params[x].value + '次'
                );
              }
            },
          },
          grid: {
            right: "5%",
            top: "20%",
            left: "5%",
            bottom: "5%",
            containLabel: true
          },
          xAxis: {
            type: "category",
            boundaryGap: true,
            data: newData.name,
            axisLabel: {
              //坐标轴刻度标签的相关设置。
              interval: 0, //设置为 1，表示『隔一个标签显示一个标签』
              //	margin:15,
              textStyle: {
                color: "#65D5FF",
                fontStyle: "normal",
                fontSize: 16
              }
            },
            axisTick: {
              //坐标轴刻度相关设置。
              show: false
            },
            axisLine: {
              //坐标轴轴线相关设置
              lineStyle: {
                color: "rgba(77, 128, 254, 0.2)"
              }
            },
            splitLine: {
              //坐标轴在 grid 区域中的分隔线。
              show: false,
              lineStyle: {
                color: "rgba(77, 128, 254, 0.2)"
              }
            }
          },
          yAxis: [
            {
              type: "value",
              splitNumber: 3,
              axisLabel: {
                textStyle: {
                  color: "#65D5FF",
                  fontStyle: "normal",
                  fontSize: 16
                }
              },
              axisLine: {
                show: false
              },
              axisTick: {
                show: false
              },
              splitLine: {
                show: true,
                lineStyle: {
                  color: "rgba(77, 128, 254, 0.2)"
                }
              }
            }
          ],
          series: [
            {
              name: '',
              type: "pictorialBar",
              barWidth: "60%",
              stack: "总量",
              label: {
                normal: {
                  show: false
                }
              },
              itemStyle: {
                normal: {
                  color: {
                    type: "linear",
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                      {
                        offset: 0,
                        color: "#00ae9d" // 0% 处的颜色
                      },
                      {
                        offset: 0.5,
                        color: "#90d7ec" // 0% 处的颜色
                      },
                      {
                        offset: 1,
                        color: "rgba(0, 34, 66, 0.5)" // 100% 处的颜色
                      }
                    ],
                    globalCoord: false // 缺省为 false
                  } //渐变颜色
                }
              },
              symbol:
                      "path://M12.000,-0.000 C12.000,-0.000 16.074,60.121 22.731,60.121 C26.173,60.121 -3.234,60.121 0.511,60.121 C7.072,60.121 12.000,-0.000 12.000,-0.000 Z",

              data: newData.count1
            }],
        }
      },
      immediate: true,
      deep: true
    },

  },
  mounted() {
    this.company_id = local.get("company_id");
    // bus.$off('full')
    bus.$on('refreshChart',params =>{
      if(params.show){
        this.showChart = false
        let t = setTimeout(() =>{
          this.showChart = true
          clearTimeout(t)
        },100)
        // clearTimeout(t)
      }
    })
    this.getData();
  },
  methods: {
    getData() {
      api.get("/v1/pc/plantingScreen/oneChart2",{companyId:localStorage.getItem("company_id")}).then(response => {
        this.list = response.data;
        this.list.farmingAccountedFors.forEach((item,index) =>{
          this.cdata.name.push(item.farmName)
          this.cdata.count1.push(item.count)
          this.cdata.count2.push(item.count - 1)
          if(this.cdata.name.length >= 8){
            this.cdata.end = 8 / this.cdata.name.length * 100
          }else{
            this.cdata.end = 100
          }
        })
        const { config } = this;
        this.config = {
          ...config,
          data: this.list.farmingAccountedFors.map(m =>{
            return {
              name: m.farmName,
              value: m.count
            }
          })
        };
        this.labelConfig = {
          data: this.list.farmingAccountedFors.map(item => item.farmName)
        };
        const total = this.prefixInteger(this.list.total, 4).split("");
        [
          this.total.first,
          this.total.second,
          this.total.third,
          this.total.forth
        ] = total;
        this.chartFlag = true;
      });
    },
    prefixInteger(num, length) {
      return (Array(length).join("0") + num).slice(-length);
    }
  }
};
</script>

<style lang="scss">
.center-cmp {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-direction: column;

  .cc-header {
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 30px;
  }

  .cc-details {
    height: 120px;
    display: flex;
    justify-content: center;
    font-size: 32px;
    align-items: center;

    .card {
      background-color: rgba(4, 49, 128, 0.6);
      color: #08e5ff;
      height: 70px;
      width: 70px;
      font-size: 45px;
      font-weight: bold;
      line-height: 70px;
      text-align: center;
      margin: 10px;
    }
  }

  .cc-main-container {
    position: relative;
    flex: 1;
    display: flex;

    .ccmc-middle {
      width: 50%;
      height: 90%;

      .active-ring-name {
        font-size: 20px !important;
      }
    }

    .ccmc-left,
    .ccmc-right {
      width: 25%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 24px;

      span {
        font-size: 40px;
        font-weight: bold;
      }

      .station-info {
        height: 80px;
        display: flex;
        align-items: center;
      }
    }

    .ccmc-left {
      align-items: flex-end;

      span {
        margin-left: 20px;
      }
    }

    .ccmc-right {
      align-items: flex-start;

      span {
        margin-right: 20px;
      }
    }
  }

  .label-tag {
    position: absolute;
    width: 500px;
    height: 30px;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
